<template>
  <b-form-group
    :disabled="disabled"
    label-for="inscricaoInput"
    :label="labelTitle"
  >
    <validation-observer ref="cadFederalObserver">
      <validation-provider
        ref="cadFederalProvider"
        #default="{ errors }"
        vid="cadInscricao"
        :rules="!isRequired? '' : ('required|' + (isCnpj_ ? 'cnpjDocumento' : 'cpfDocumento'))"
        name="Inscrição"
      >
        <b-input-group>
          <masked-input
            id="inscricaoInput"
            ref="cadastroFederal"
            v-model="value_"
            :disabled="disabled"
            :class="{'is-invalid' : errors.length > 0}"
            :mask="mascaraCadFederal"
            class="form-control"
            @blur="validarBlur"
            @input="enviarParaPai"
          />
          <b-input-group-append v-if="isCnpj_ && isToBuscarDados">
            <b-button
              v-b-tooltip.hover
              :disabled="cadastroFederalInvalido || disabled"
              title="Busca dados da RFB"
              variant="primary"
              style="float:right"
              @click="buscarCadastroFederal()"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <loading
        :show-cancel-button="false"
        :is-loading="isLoading"
        :descricao="'Buscando'"
      />
    </validation-observer>
  </b-form-group>
</template>

<script>
import {
  required,
} from '@validations'
import { cpfDocumento, cnpjDocumento } from '@pilar/plugins/p-validation/p-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CadastroFederalInput',
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    isCnpj: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    buscarDados: {
      type: Function,
      default: () => {},
    },
    validacaoBlur: {
      type: Function,
      default: () => {},
    },
    labelTitle: {
      type: String,
      default: 'Inscrição',
    },
    isToBuscarDados: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cpfDocumento,
      cnpjDocumento,
      required,
      cadastroFederal: '',
      isLoading: false,
      value_: '',
      isCnpj_: true,
      erroManual: false,
    }
  },
  computed: {
    cadastroFederalInvalido: {
      get() {
        return !(this.value_ ? this.value_.replace(/\D/g, '').length === 14 : false) || this.erroManual
      },
    },
  },
  watch: {
    isCnpj(val, oldValue) {
      if (val !== oldValue) {
        this.isCnpj_ = val
      }
    },
    value(val, oldValue) {
      if (val !== oldValue) {
        this.value_ = val
      }
    },
  },
  created() {
    this.isCnpj_ = this.isCnpj
    this.value_ = this.value
  },
  methods: {
    buscarCadastroFederal() {
      this.erroManual = false
      this.$refs
        .cadFederalObserver
        .validate()
        .then(valid => {
          if (!valid) {
            this.$erro('CNPJ inválido')
            return
          }
          this.isLoading = true
          this.buscarDados(this.value_)
            .then(payload => {
              // eslint-disable-next-line no-param-reassign
              payload.data.ativa = true
              this.$emit('dados-empresa', payload)
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao buscar empresa. Tente novamente em alguns instantes',
                  icon: 'fa-solid fa-circle-exclamation',
                  variant: 'danger',
                },
              })
            })
        })
    },
    mascaraCadFederal() {
      return this.isCnpj
        ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
        : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    },
    /* eslint no-underscore-dangle: 0 */
    enviarParaPai() {
      this.$emit('input', this.value_)
    },
    validarBlur() {
      this.erroManual = false
      if (this.value_ && typeof this.validacaoBlur === 'function') {
        this.$refs
          .cadFederalObserver
          .validate()
          .then(valid => {
            if (valid) {
              this.validacaoBlur()
            }
          })
      }
    },
    addErroValidacao(erro) {
      this.erroManual = true
      this.$refs.cadFederalObserver.setErrors({ cadInscricao: [erro] })
      // this.$refs
      //   .cadFederalObserver
      //   .validate()
      //   .then(valid => {
      //     if (valid) {
      //       console.log('XLAAAA')
      //     }
      //   })
      // {
      //   inscricaoInput: [erro],
      // }) // cadFederalProvider.applyResult(erro)
    },
  },
}
</script>
